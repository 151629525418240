function datepickertstock(){

    $( ".datepicker" ).datepicker({});

}

function notifications(){

    $('.mark-as-read').click(function() {
        let request = sendMarkRequest($(this).data('id'), $(this).data('csrf'), $(this).data('route'));
        request.done(() => {
            $(this).parents('div.alert').remove();
        });
    });
    $('#mark-all').click(function() {
        let request = sendMarkRequest(null, $(this).data('csrf'), $(this).data('route'));
        request.done(() => {
            $('div.alert').remove();
        })
    });

}

function sendMarkRequest(id = null, csrf = null, route = null) {
    return $.ajax(route, {
        method: 'POST',
        data: {
            '_token': csrf,
            'id': id
        }
    });
}


function initialise() {
    datepickertstock()
    notifications()
}

$(document).ready(initialise);
