/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

 import $ from "jquery";
 // important! sets jQuery global otherwise jQuery not found
 global.$ = global.jQuery = require('jquery');


 import bootstrap from 'bootstrap'

require('./main');
require('./vendor/bootstrap.min');
require('./vendor/jquery-3.6.0.slim.min');
require('./vendor/jquery-ui.min');
require('./jquery.cookie');
require('./jquery.magnific-popup');
require('./main.admin');


window.Vue = require('vue');

window.axios = require('axios');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('autocomplete', require('./components/AutoComplete.vue').default);
Vue.component('Chart', require('./components/Chart.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});
